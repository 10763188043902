<!--
 * @Author: your name
 * @Date: 2021-01-27 11:15:45
 * @LastEditTime: 2021-02-05 17:39:29
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \weilaiyouni_web\src\views\code\add.vue
-->
<template>
  <div style="width:100%;">
    <el-row>
      <el-col>
        <div class="list-box">
          <el-table border stripe :data="list">
            <el-table-column prop="organizationCode" align="center" label="序号" width="90">
              <template slot-scope="scope">
                <div>{{scope.$index+1}}</div>
              </template>
            </el-table-column>
            <el-table-column prop="customerName" align="center" label="客户名称" :width="editShow?150:200"></el-table-column>
            <el-table-column prop="actualAmount" align="center" label="面额" :width="editShow?130:170"></el-table-column>
            <el-table-column prop="bindPhone" align="center" label="手机号" :width="editShow?130:170"></el-table-column>
            <el-table-column width="120" label="操作" v-if="editShow">
              <template slot-scope="scope">

                <div>
                  <i slot="reference" class="el-icon-delect btn-hover" @click="delectItem(scope.row.id)">删除</i>
                </div>
              </template>
            </el-table-column>
          </el-table>
          <div class="block">
            <el-pagination layout="total,prev, pager, next,sizes" @size-change="handleSizeChange" @current-change="handleCurrentChange" background :page-size="pageSize" :current-page="pageNum" :total="total">
            </el-pagination>
          </div>
        </div>
      </el-col>
      <el-col>
        <el-button type="primary" v-debounce="submitApproval" v-if="editType == 3">确认提交</el-button>
        <el-button type="info" @click="dialogClose()">关闭窗口</el-button>

      </el-col>
    </el-row>
  </div>
</template>
<script>
import { getCookies } from '@/utils/utils'
import { getBatchVoucherList, deleteAutonym, submitApproval } from '@/api/codes';
export default {
  props: ['isAddDialog', 'editId', 'editType'],
  data() {
    return {
      list: [],
      total: 0,//分页总页数
      pageNum: 1,//分页页数
      pageSize: 10,//每页显示多少
    }
  },
  computed: {
    editShow() {
      return [2].indexOf(this.editType) != -1 || !this.editType
    }
  },
  mounted() {
    this.editType && this.editId && this.getDateil();
  },
  methods: {
    changeListItem(msg) {
      let list = JSON.parse(JSON.stringify(this.list));
      this.$set(this.list, list.findIndex(item => item.id == msg.id), msg)
    },
    dialogClose() {
      this.active = 0;
      this.$emit('update:isAddDialog', false)
    },
    handleSizeChange(val) {
      this.pageSize = val;
      this.getDateil();
    },
    handleCurrentChange(val) {
      this.pageNum = val;
      this.getDateil();
    },
    submitApproval(id) {
      let that = this;
      if (this.list.length < 1 || this.list.length == 0) {
        this.$message({
          title: '错误',
          type: 'error',
          message: '批次客户为0，不可提交',
          duration: 1500
        });
        return false;
      }
      submitApproval({ batchId: that.editId, submitBy: getCookies('sysId') })
        .then((response) => {
          if (response.code == 200) {
            that.dialogClose();
          }
          that.$message({
            title: '错误',
            type: response.code == 200 ? 'success' : 'error',
            message: response.msg || response.error_msg || response.message,
            duration: 1500
          });
        })
    },
    delectItem(id) {
      let that = this;
      deleteAutonym(id)
        .then((response) => {
          if (response.code == 200) {
            that.list = that.list.filter(item => item.id !== id);
          }

        })
    },
    getDateil() {
      let that = this;
      let param = {
        isPage: 1,
        pageNum: that.pageNum,
        pageSize: that.pageSize,
        batchType: 3,
        batchId: this.editId
      };

      getBatchVoucherList(param)
        .then((response) => {
          if (response.code == 200) {
            that.total = response.data.total;
            let list = response.data.records;
            that.list = list.map(v => {
              return { ...v, isSelected: false }
            })
          }

        })
    },
  }
}
</script>
<style scoped>
.list-box {
  box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.1);
  position: relative;
  display: inline-block;
  width: 631px;

  max-height: 600px;
  margin: 0px auto 20px;
  overflow: hidden;
  overflow-y: auto;
  border: 1px solid #b4b4b4;
}
</style>