import { render, staticRenderFns } from "./batchImportCode.vue?vue&type=template&id=50c376bd&scoped=true&"
import script from "./batchImportCode.vue?vue&type=script&lang=js&"
export * from "./batchImportCode.vue?vue&type=script&lang=js&"
import style0 from "./batchImportCode.vue?vue&type=style&index=0&id=50c376bd&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "50c376bd",
  null
  
)

export default component.exports