<template>
  <div>
    <el-form ref="form" :model="form" :rules="rules" label-width="auto" inline size="small" style="text-align:left">
      <el-form-item label="标题" prop="title">
        <el-input v-model="form.title" placeholder="请输入标题" style="width:255px;"></el-input>
      </el-form-item>
      <el-form-item label="审批单号" prop="auditCode">
        <el-input v-model="form.auditCode" placeholder="请输入审批单号" style="width:255px;"></el-input>
      </el-form-item>
      <el-form-item label="发放卡券" prop="vouchersId">
        <el-select v-model="form.vouchersId" placeholder="请选择发放卡券" style="width:255px;">
          <el-option v-for="(item,index) in vouchersList" :key="index" :label="item.vouchersTitle" :value="item.vouchersId"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="导入模板">
        <a style="margin:0px;font-size:18px;padding:5px;" href="https://template.gongxingtech.com/期油-直接发放导入Excel.xlsx">下载模板</a>
      </el-form-item>
      <el-form-item>
        <el-upload class="upload-demo" accept=".xls,.xlsx" :action="url + '/wlynVouchers/batchImportVouchers'" :on-preview="handleTip" :on-remove="handleTip" :on-success="handleSuccess" :before-remove="handleTip" :headers="headers" :limit="1">
          <el-button size="small" type="primary">点击上传</el-button>
          <div slot="tip" class="el-upload__tip">只允许上传Excel文件</div>
        </el-upload>
      </el-form-item>
      <div class="list-box">
        <el-table border stripe :data="list">
          <el-table-column type="index" align="center" label="序号" width="60"></el-table-column>
          <el-table-column prop="userName" align="center" label="客户名称"></el-table-column>
          <el-table-column prop="phone" align="center" label="手机号"></el-table-column>
          <el-table-column prop="number" align="center" label="数量"></el-table-column>
          <el-table-column label="操作" align="center">
            <template slot-scope="scope">
              <i class="el-icon-edit btn-hover" @click="delItem(scope.$index)">删除</i>
            </template>
          </el-table-column>
        </el-table>
      </div>
    </el-form>
    <div>
      <el-button type="primary" v-debounce="onSubmit">立即生成</el-button>
      <el-button @click="dialogClose">取消</el-button>
    </div>
  </div>
</template>
<script>
import { getVouchersList } from '@/api/setting';
import { confirmImportVouchers } from '@/api/codes';
import { baseURL } from '@/utils/http';
import { getCookies } from '@/utils/utils'
export default {
  props: ['isAddDialog'],
  data() {

    return {
      list: [],
      url: baseURL,
      headers: {
        'x-token': getCookies('x-token')
      },
      uploadData: {
        // sysId: getCookies('sysId'),
        // organizationId: getCookies('organizationId'),
      },
      vouchersList: [],
      form: {
        vouchersId: '',
        title: '',
        auditCode: ''
      },
      rules: {
        vouchersId: [
          { required: true, message: '请选择折扣券', trigger: 'change' },
        ],
        title: [
          { required: true, message: '请输入标题', trigger: 'blur' },
        ],
        auditCode: [{ required: true, message: "审批单号不可为空", trigger: "blur" }],
      },
    }
  },
  watch: {
    isAddDialog(n, o) {
      if (n) {
        this.form.vouchersId = '';
      }
    }
  },
  mounted() {
    this.getVouchersList();
  },
  methods: {
    handleSuccess(e) {
      if (e.code == 200) {
        this.list = e.data;
        this.active = 2;
      }
      this.$message({
        message: e.msg || e.error_msg || e.message,
        type: 'success'
      });
    },
    handleTip(e) {
      this.$message({
        message: e.msg || e.error_msg,
        type: 'success'
      });
    },
    getVouchersList() {
      let that = this;
      let param = {
        isPage: 0,
        pageNum: 1,
        pageSize: 100000000,
        vouchersType: 0,
        usedArea: '440000'
      };
      getVouchersList(param)
        .then((response) => {
          if (response.code == 200) {
            that.vouchersList = response.data.records;
          } else {
            that.$message.error({
              title: '错误',
              message: response.msg,
              duration: 1500
            });
          }

        })
    },
    delItem(index) {
      let arr = JSON.parse(JSON.stringify(this.list));
      for (var i = index, len = arr.length - 1; i < len; i++)
        arr[i] = arr[i + 1];
      arr.length = len;

      this.list = arr;
    },
    dialogClose() {
      this.$emit('update:isAddDialog', false)
    },
    onSubmit(formName = 'form') {
      var that = this;
      if (that.list.length > 0) {
        let form = JSON.parse(JSON.stringify(that.form));
        form.list = that.list
        form.codeType = 3
        form.exchangeType = 0
        this.$refs['form'].validate((valid) => {
          if (valid) {
            confirmImportVouchers(form)
              .then((response) => {
                if (response.code == 200) {
                  that.dialogClose();
                }
                that.$message({
                  title: '错误',
                  type: response.code == 200 ? 'success' : 'error',
                  message: response.msg || response.error_msg || response.message,
                  duration: 1500
                });

              })
          } else {
            this.$message.error({
              title: '错误',
              message: '请检查重试',
              duration: 1500
            });
            return false;
          }
        })
      }
    }
  }
}
</script>
<style scoped>
.el-form-item {
  text-align: left;
}
.list-box {
  box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.1);
  height: 300px;
  margin: 0px auto 20px;
  overflow: hidden;
  overflow-y: auto;
  border: 1px solid #b4b4b4;
}
</style>