<!--
 * @Author: your name
 * @Date: 2020-12-11 10:19:32
 * @LastEditTime: 2021-02-05 17:45:34
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \scweb\src\views\service\outlets\index.vue
-->
<template>
<div>
    <el-tabs v-model="active" @tab-click="handleClick">
        <el-tab-pane label="按批次显示" name="first">
            <BatchList v-if="active == 'first'" ></BatchList>
        </el-tab-pane>
        <el-tab-pane label="按客户显示" name="second">
            <CustomerList v-if="active == 'second'"  ></CustomerList>
        </el-tab-pane>
    </el-tabs>
    
    
</div>

    
</template>
<script>

import BatchList from './components/batchList.vue'
import CustomerList from './components/customerList.vue'

export default {
    
    components:{
        BatchList,
        CustomerList
    },
    data(){
        return {
            isAddDialog:false,
            active: 'first',
        }
    
    },
    methods:{
        handleClick(){

        }
    }
}
</script>
<style lang="scss" scoped>
@import "@/common/scss/base";

</style>