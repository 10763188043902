
<template>
  <div>
    <el-form label-width="95px">
      <el-row>
        <el-col :span="24">
          <div class="form-item-box">
            <el-form-item label="金额：">
              <el-input style="width:110px;float:right;" size="mini" placeholder="请输入金额" v-model.number="search.amount"></el-input>
            </el-form-item>
          </div>
          <div class="form-item-box">
            <el-form-item label="核销码：">
              <el-input style="width:110px;float:right;" size="mini" placeholder="请输入核销码" v-model.number="search.code"></el-input>
            </el-form-item>
          </div>
          <div class="form-item-box">
            <el-form-item label="客户名称：" label-width="100px">
              <el-input style="width:130px;float:right;" size="mini" placeholder="请输入客户名称" v-model.number="search.userName"></el-input>
            </el-form-item>
          </div>
          <div class="form-item-box">
            <el-form-item label="模糊查询手机：" label-width="120px">
              <el-input style="width:130px;float:right;" size="mini" placeholder="请输入手机号" v-model.number="search.likeName"></el-input>
            </el-form-item>
          </div>
          <div class="form-item-box">
            <el-form-item label="模糊查询姓名：" label-width="120px">
              <el-input style="width:130px;float:right;" size="mini" placeholder="请输入名称" v-model.number="search.likePhone"></el-input>
            </el-form-item>
          </div>
          <div class="form-item-box">
            <el-form-item label="使用状态：">
              <el-select size="mini" style="width:120px;" v-model="search.isUse" placeholder="选择审批状态">
                <el-option label="使用" value="0"></el-option>
                <el-option label="未使用" value="1"></el-option>
                <el-option label="撤回" value="2"></el-option>
              </el-select>
            </el-form-item>
          </div>
          <div class="form-item-box">
            <el-form-item label="分支机构：">
              <el-select size="mini" v-model="organizationId" placeholder="请选择所属分支机构" style="width:250px;">
                <el-option v-for="(item,index) in organizationList" :key="index" :label="item.organizationName" :value="item.id"></el-option>
              </el-select>
            </el-form-item>
          </div>
          <div class="form-item-box">
            <el-form-item label="创建时间：">
              <el-date-picker style="width:314px;margin:6px 0px 0px 0px;float:left;" v-model="search.createTime" size="mini" type="datetimerange" value-format="yyyy-MM-dd HH:mm:ss" start-placeholder="开始日期" end-placeholder="结束日期"></el-date-picker>
            </el-form-item>
          </div>
          <div class="form-item-box">
            <el-form-item label="使用时间：">
              <el-date-picker style="width:314px;margin:6px 0px 0px 0px;float:left;" v-model="search.usedTime" size="mini" type="datetimerange" value-format="yyyy-MM-dd HH:mm:ss" start-placeholder="开始日期" end-placeholder="结束日期"></el-date-picker>
            </el-form-item>
          </div>

          <el-button style="float:left;margin:6px 15px 0px" type="primary" size="mini" icon="el-icon-search" @click="handleCurrentChange(1)">查询</el-button>
          <el-button style="float:left;margin:6px 15px 0px 0px;" type="default" size="mini" @click="clearSearch()">重置</el-button>
        </el-col>
        <el-col :span="2" style="margin:20px 0px;">
          <!-- <a :href="url+
                '/user/exportExcelCode?codeType=1&organizationId='+
                organizationId+
                '&startTime='+
                startTime+
                '&endTime='+
                endTime+
                '&isUse='+
                isUse+
                '&timeType='+
                timeType" >
                    <el-button style="float:right;width:100px;float:right;margin:6px 10px;"  type="primary" size="mini" >批量导出</el-button>
                    </a>-->
        </el-col>
        <el-table stripe :data="list" style="box-shadow: 0 1px 4px 0 rgba(0,0,0,0.1);overflow:auto">
          <el-table-column align="center" label="序号" width="50">
            <template slot-scope="scope">
              <div>{{scope.$index+1}}</div>
            </template>
          </el-table-column>
          <el-table-column prop="organizationName" align="center" label="所属机构" width="130"></el-table-column>

          <el-table-column prop="deductibleAmount" align="center" label="面额（元）" width="90"></el-table-column>
          <el-table-column prop="customerName" align="center" label="客户" width="130"></el-table-column>
          <el-table-column prop="bindPhone" align="center" label="手机" width="110"></el-table-column>
          <el-table-column prop="createByName" align="center" label="创建人" width="120"></el-table-column>
          <el-table-column prop="createTime" align="center" label="创建时间" width="100"></el-table-column>
          <el-table-column prop="auditStatus" align="center" label="审批状态" width="80">
            <template slot-scope="scope">
              <el-tag class="el-tag-style" size="mini" disable-transitions :type="auditStatusTag[scope.row.auditStatus] && auditStatusTag[scope.row.auditStatus].type" v-text="auditStatusTag[scope.row.auditStatus] && auditStatusTag[scope.row.auditStatus].text"></el-tag>
            </template>
          </el-table-column>
          <el-table-column prop="isUse" align="center" label="状态" width="100">
            <template slot-scope="scope">
              <el-tag class="el-tag-style" size="mini" type="primary" disable-transitions v-if="scope.row.isUsed == 0">未使用</el-tag>
              <el-tag class="el-tag-style" size="mini" type="success" disable-transitions v-if="scope.row.isUsed == 1">已使用</el-tag>
              <el-tag class="el-tag-style" size="mini" type="info" disable-transitions v-if="scope.row.isUsed == 2">已撤回</el-tag>
            </template>
          </el-table-column>
          <el-table-column prop="usedTime" align="center" label="使用时间" width="100"></el-table-column>
          <el-table-column prop="createPhone" align="center" label="操作">
            <template slot-scope="scope">
              <el-button icon="el-icon-refresh-left" size="mini" class="btn-hover" @click="recall(scope.row.rid,scope.$index)" :disabled="scope.row.isUsed != 0">撤回</el-button>
            </template>
          </el-table-column>
        </el-table>
        <div class="block">
          <!-- <span style="float:left;" >显示总数</span> -->
          <el-pagination layout="total,prev, pager, next,sizes" @size-change="handleSizeChange" @current-change="handleCurrentChange" background :page-size="pageSize" :current-page="pageNum" :total="total"></el-pagination>
        </div>
      </el-row>
    </el-form>
  </div>
</template>
<script>
import { voucherQueryList, backVouchers } from '@/api/codes'
import { organizationList } from '@/api/setting';
import { getCookies, auditStatusTag } from '@/utils/utils'
import { baseURL } from '@/utils/http'

import AddBatchCode from './addBatchCode.vue'
import AddSingleCode from './addSingleCode.vue'

export default {

  components: {
    AddBatchCode,
    AddSingleCode
  },
  data() {
    return {
      url: baseURL,
      batchType: 3,        //批次类型(0.核销码,1.实名发放)
      auditStatusTag: auditStatusTag,
      search: {
        isUse: '',
        createTime: '',
        usedTime: '',
        amount: '',
        code: '',
        userName: '',
        likeName: '',
        likePhone: '',
      },
      roleId: getCookies('roleId'),
      organizationId: '',
      list: [],
      organizationList: [],
      total: 0,//分页总页数
      pageNum: 1,//分页页数
      pageSize: 10,//每页显示多少
    }
  },
  watch: {
    isAddDialog() {
      this.getList();
    },

  },
  mounted() {
    this.organizationId = Number(this.roleId) === 1 ? getCookies('organizationId') : '';
    this.getList();
    this.getOrganizationList();
  },
  methods: {
    recall(id, index) {
      let that = this;
      this.$confirm('是否撤回?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then((res) => {
        backVouchers(id)
          .then((response) => {
            if (response.code == 200) {
              // that.list[index].isuse = 2;
              let item = JSON.parse(JSON.stringify(that.list[index]));
              console.log(item)
              item['isUse'] = 2;
              console.log(item)
              that.$set(that.list, index, item)
            }
            that.$message({
              type: response.code == 200 ? 'success' : 'default',
              duration: 2000,
              message: response.msg || response.error_msg,
            });
          })
      });
    },
    changeCheck(checkKey) {
      this.$nextTick(() => {
        this[checkKey] = this[checkKey].replace(/[^\w\.\/]/ig, '')
      })
    },
    handleSizeChange(val) {
      this.pageSize = val;
      this.getList();
    },
    handleCurrentChange(val) {
      this.pageNum = val;
      this.getList();
    },
    setDisable(id, isDisable, index) {
      let that = this;
      let param = {
        "id": id,
        "isDisable": Number(isDisable) == 1 ? 0 : 1
      }
      outletsDisable(param)
        .then((response) => {
          if (response.code == 200) {
            that.list[index].isDisable = Number(isDisable) == 1 ? 0 : 1;
          }
          that.$message({
            title: '错误',
            type: response.code == 200 ? 'success' : 'error',
            message: response.msg || response.error_msg || response.message,
            duration: 1500
          });

        })
    },
    getOrganizationList() {
      let that = this;

      let param = {
        isPage: 0,
        pageNum: 1,
        pageSize: 1000000,
        orgId: getCookies('organizationId'),
      };
      organizationList(param)
        .then((response) => {
          if (response.code == 200) {
            that.organizationList = response.data.records;
          }

        })
    },
    getList() {
      let that = this;
      let param = {
        isPage: 1,
        pageNum: that.pageNum,
        pageSize: that.pageSize,
        batchType: 3,
      };
      let search = that.search;
      if (search.userName) {
        param['userName'] = search.userName;
      }
      param['isUse'] = search.isUse;
      param['amount'] = search.amount;
      param['code'] = search.code;
      param['userStartTime'] = search.usedTime[0];
      param['userEndTime'] = search.usedTime[1];
      param['startTime'] = search.createTime[0];
      param['endTime'] = search.createTime[1];
      param.exchangeType = 0
      voucherQueryList(param)
        .then((response) => {
          if (response.code == 200) {
            that.total = response.data.total;
            that.list = response.data.records;
          }
        })
    },
    clearSearch() {
      this.search = {
        isUse: '',
        createTime: '',
        usedTime: '',
        amount: '',
        code: '',
        userName: '',
        likeName: '',
        likePhone: '',
      }

      this.total = 0
      this.pageNum = 1
      this.getList()

    },
  },
}
</script>
<style lang="scss" scoped>
@import "@/common/scss/base";
.el-tag-style {
  width: 60px;
  text-align: center;
}
.form-item-box {
  float: left;
  height: 46px;
}
.el-form-item {
  margin: 0;
}

.btn-blue-hover {
  margin: 0 6px;
  color: $hoverRed;
}
.btn-blue-hover:hover {
  color: $hoverYellow;
}

.btn-red-hover {
  margin: 0 6px;
  color: $hoverBlue;
}
.btn-red-hover:hover {
  color: $hoverYellow;
}
</style>